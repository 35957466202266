<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="pr-10 pl-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="5">
          <div class="lblTitle">
            <h3>{{ $t('messages.customerName') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-autocomplete
              v-model="customer"
              :items="customers"
              :no-data-text="$t('messages.noJobsSelect')"
              :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
              item-value="id"
              clearable
              dense
              single-line
              outlined
            />
          </div>
        </v-col>
        <v-col cols="5">
          <div class="lblTitle">
            <h3>状態</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-select
              v-model="isSubmitted"
              :items="statusSubmit"
              item-text="text"
              item-value="value"
              outlined
              dense
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-btn
            dense
            color="forth"
            class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-star"
            @click="$router.push({path: '/jobs/job-add'})"
          >
            <span class="font-weight-bold text-h5">{{ $t('messages.addShift') }}</span>
          </v-btn>
        </v-col>
        <v-col cols="4" />
        <v-col
          class="text-button align-self-center text-center"
          cols="5"
          sm="5"
          md="5"
        >
          <div class="align-self-center">
            <v-text-field
              v-model="keySearch"
              dense
              single-line
              outlined
              clearable
              placeholder="ジョブ名を入力"
              @keydown.enter.native="onSearch()"
            />
          </div>
        </v-col>
        <v-col
          class="text-button"
          cols="1"
        >
          <v-btn
            block
            color="accent"
            class="text-h4"
            @click="onSearch()"
          >
            {{ $t('messages.search') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="factoryOrders"
        :options.sync="options"
        :items-per-page="10"
        :server-items-length="totalItems"
        stle
        :loading-text="$t('table.messages.loading')"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 100],
          showFirstLastPage: false,
          'items-per-page-text': $t('table.messages.items_per_page'),
        }"
        :no-data-text="$t('table.messages.no_data')"
      >
        <template
          v-slot:footer.page-text
          class="mr-0"
        >
          {{ $t('table.messages.page') }}
          {{ options.page }}
        </template>
        <template v-slot:item="props">
          <tr
            @click="$router.push({
              path: '/jobs/job-edit/' + props.item.jobId,
            })"
          >
            <td class="text-center">
              {{ getIndex(props.index) }}
            </td>
            <td class="text-center">
              {{ props.item.jobName ? props.item.jobName: '--' }}
            </td>
            <td class="text-center">
              {{ props.item.name ? props.item.name: '--' }}
            </td>
            <td class="text-center">
              <span
                v-if="props.item.jobStatus"
                class="text-color-edit"
              >
                稼働中
              </span>
              <span v-else>
                停止中
              </span>
            </td>
            <td class="text-center">
              <span>{{ props.item.jobCreated }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.jobUpdated }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { convertTime } from '../../util/covertTime'

  export default {
    name: 'Jobs',
    data () {
      return {
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        keySearch: '',
        options: {},
        currentOptions: {},
        loading: false,
        headers: [],
        factoryOrders: [],
        dessert: [],
        dialog: false,
        arrUpdateJobs: [],
        pins: [],
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        totalItems: 0,
        totalStt: 0,
        pageOld: 0,
        countOld: 0,
        customers: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        customer: '',
        isSubmitted: '',
        statusSubmit: [
          {
            value: '',
            text: 'すべて',
          },
          {
            value: 1, // true
            text: '稼働中',
          },
          {
            value: 2, // false
            text: '停止中',
          },
        ],
      }
    },
    computed: {
      ...get('job', ['list', 'message', 'status', 'error']),
      customerList: get('customer@list.data.customers'),
    },
    watch: {
      options: {
        handler () {
          this.initialize()
          if (this.pageOld !== 0) {
            if (this.options.page > this.pageOld && this.options.page !== 1) {
              this.totalStt += this.factoryOrders.length
            } else if (this.options.page < this.pageOld && this.options.page !== 1) {
              this.totalStt -= this.countOld
            } else {
              this.totalStt = 0
            }
          }
          this.pageOld = this.options.page
          this.countOld = this.factoryOrders.length
        },
        deep: true,
      },
      list (value) {
        const arr = []
        if (value) {
          if (value.data.count <= this.options.itemsPerPage && this.options.page !== 1) {
            this.options.page = 1
            return
          }
          this.totalItems = value.data.count
          value.data.jobs.forEach(job => {
            const factory = job.customer
            arr.push({
              name: factory.code + '_' + factory.user.name,
              code: factory.code,
              jobId: job.id,
              jobName: job.name,
              jobStatus: job.isActive,
              jobCreated: convertTime(job.createdAt),
              jobUpdated: convertTime(job.updatedAt),
            })
          })
        }
        this.factoryOrders = arr
      },
      message (value) {
        if (value === 'updateSuccess') {
          this.showSnackBar('messages.updateSuccess')
        }
      },
      keySearch (value) {
        if (!value) {
          this.initialize()
        }
      },
      customerList (value) {
        value.forEach(item => {
          this.customers.push(item)
        })
      },
      customer (value) {
        this.initialize()
      },
      isSubmitted (value) {
        this.initialize()
      },
    },
    mounted () {
      this.arrUpdateJobs = []
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'center',
          value: 'index',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '5%',
        },
        {
          text: this.$t('messages.jobName'),
          align: 'center',
          value: 'jobName',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '10%',
        },
        {
          text: this.$t('table.headers.factoryName'),
          align: 'center',
          value: 'name',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '10%',
        },
        {
          text: this.$t('table.headers.status'),
          align: 'center',
          value: 'status',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '10%',
        },
        {
          text: this.$t('table.headers.created_at'),
          align: 'center',
          value: 'created_at',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '10%',
        },
        {
          text: this.$t('table.headers.updatedTime'),
          align: 'center',
          value: 'updated_at',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '10%',
        },
      ]
      this.$store.dispatch('customer/getCustomers')
      if (this.$route.query) {
        if (this.$route.query.success) {
          this.showSnackBar('messages.success')
        } else if (this.$route.query.deleteSuccess) {
          this.showSnackBar('messages.deleteSuccess')
        } else if (this.$route.query.updateSuccess) {
          this.showSnackBar('messages.updateSuccess')
        }
      }
    },
    methods: {
      initialize () {
        const tmpOptions = { ...this.options }
        tmpOptions.limit = parseInt(tmpOptions.itemsPerPage)
        tmpOptions.offset = (tmpOptions.page - 1) * tmpOptions.limit

        if (this.keySearch) {
          tmpOptions.keyword = this.keySearch.trim()
        }

        if (this.customer) {
          tmpOptions.customerId = this.customer
        }
        if (this.isSubmitted) {
          tmpOptions.isActive = this.isSubmitted
        }

        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('job/getJobsList', tmpOptions)
      },
      onSearch () {
        if (this.options.page !== 1) {
          this.options.page = 1
        } else {
          this.initialize()
        }
      },
      handlePin (customerId, oldIsPined) {
        const payload = {
          customerId: customerId,
          isPin: !oldIsPined,
        }
        this.$store.dispatch('job/pinCustomer', payload)
        setTimeout(() => {
          this.initialize()
        }, 500)
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getNameFactory (code, name) {
        let text = ''
        if (code) {
          text += code + '_' + name
        } else {
          text += name
        }
        return text
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
    },
  }
</script>

<style scoped lang="sass">
.text-color-edit
  color: #1BC5BD
</style>
